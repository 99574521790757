.bg-color-1 {
  background-color: #f6f6f8;
}

.bg-color-2 {
  background-color: #50b5ff;
}

.bg-color-3 {
  background-color: #49c8b9;
}

.bg-color-4 {
  background-color: #f3fbfa;
}

.bg-color-5 {
  background-color: #f2f2f2;
}

.text-color-1 {
  color: #50b5ff;
}
