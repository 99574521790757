/** Layout css start */

.sidebar-container {
  min-height: calc(100vh - 81px);
  height: 100%;
  width: 350px;
  background-color: #ffffff;
}

.body-container {
  min-height: calc(100vh - 81px);
  height: 100%;
  width: 100%;
  background-color: #f6f6f8;
}

/** Layout css end */
